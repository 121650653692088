import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { path } from 'pixi.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/snow',
    meta: { title: "献给雪" },
    component: () => import('../views/lover/snow.vue')
  },
  {
    path: '/tofather1',
    meta: { title: "生日快乐" },
    component: () => import('../views/family/father.vue')
  },
 {
    path: '/secret',
    meta: { title: "输入密语" },
    component: () => import('../views/between.vue')
  },{
    path:'/birthday',
    meta: { title: "生日快乐" },
    component: () => import('../views/birthday/b2.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
